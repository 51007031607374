import type { OperationVariables, QueryHookOptions, TypedDocumentNode } from '@apollo/client';
import { useQuery } from '@apollo/client';
import { useEffect, useRef } from 'react';

import {
  useApolloContextUriWithTestMode,
  usePublicApolloContextUriWithTestMode,
} from './useApolloContextUriWithTestMode';
import { useIsTestMode } from './useIsTestMode';

export function useQueryWithTestMode<U, V extends OperationVariables>(
  document: TypedDocumentNode<U, V>,
  baseOptions: QueryHookOptions<NoInfer<U>, NoInfer<V>> = {},
  isPublic = false,
) {
  const testModeEnabled = useIsTestMode();
  const testModeLastValRef = useRef(testModeEnabled);
  const publicContextUri = usePublicApolloContextUriWithTestMode();
  const privateContextUri = useApolloContextUriWithTestMode();
  const contextUri = isPublic ? publicContextUri : privateContextUri;

  const result = useQuery(document, {
    ...baseOptions,
    context: {
      ...baseOptions.context,
      ...contextUri,
    },
  });

  const refetch = result.refetch;

  useEffect(() => {
    // We only want to refetch when going false -> true or vice versa.
    // Without this `refetch` would be called on the initial call of the hook.
    if (testModeEnabled !== testModeLastValRef.current) {
      testModeLastValRef.current = testModeEnabled;

      if (!baseOptions.skip) {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises -- eslint onboarding
        refetch();
      }
    }
  }, [refetch, testModeEnabled, baseOptions.skip]);

  return result;
}
