import { useIsTestMode } from './useIsTestMode';

export function useApolloContextUriWithTestMode() {
  const testModeEnabled = useIsTestMode();
  return {
    // @ts-expect-error -- enabling strict mode
    uri: testModeEnabled ? process.env.REACT_APP_GRAPHQL_ENDPOINT_TEST : process.env.REACT_APP_GRAPHQL_ENDPOINT,
  };
}

export function usePublicApolloContextUriWithTestMode() {
  const testModeEnabled = useIsTestMode();
  return {
    uri: testModeEnabled
      ? // @ts-expect-error -- enabling strict mode
        process.env.REACT_APP_GRAPHQL_PUBLIC_ENDPOINT_TEST
      : // @ts-expect-error -- enabling strict mode
        process.env.REACT_APP_GRAPHQL_PUBLIC_ENDPOINT,
  };
}
